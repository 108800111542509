import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import "../css/Navbar.css";

export const Navbar = () => {
  const [show, setShow] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);

  const onScroll = () => {
    const curScrollY = window.scrollY;

    if (curScrollY > prevScrollY + 20) {
      setShow(false);
      // console.log("no show nav");
    } else if (curScrollY < prevScrollY) {
      setShow(true);
      // console.log("yes show nav");
    }

    setPrevScrollY(curScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [prevScrollY]);

  return (
    <div className="navbar-component">
      <div className={show ? "active navbar-wrapper" : "hidden navbar-wrapper"}>
        <div className="navbar-image">
          <div className="navtext">
            {" "}
            <Link smooth to="home-component">
              <img alt="logo" src="/assets/logo.png" className="logo"></img>
            </Link>
          </div>
        </div>

        <ol className="navbar-items">
          <div className="navtext">
            {" "}
            <Link smooth to="about-component">
              <p>About</p>
            </Link>
          </div>
          {/* <div className="navtext">
            {" "}
            <Link smooth to="experience-component">
              Experience
            </Link>
          </div> */}
          <div className="navtext">
            {" "}
            <Link smooth to="projects-component">
              <p>Projects</p>
            </Link>{" "}
          </div>
          <div className="navtext">
            {" "}
            <Link smooth to="contact-component">
              <p>Contact</p>
            </Link>{" "}
          </div>
          <div className="navtext">
            <a
              href="https://www.linkedin.com/in/annika-h-xu/"
              target="_blank"
              rel="noreferrer"
            >
              {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="28"
              height="28"
              viewBox="0 0 48 48"
            >
              <path d="M 11.5 6 C 8.4802259 6 6 8.4802259 6 11.5 L 6 36.5 C 6 39.519774 8.4802259 42 11.5 42 L 36.5 42 C 39.519774 42 42 39.519774 42 36.5 L 42 11.5 C 42 8.4802259 39.519774 6 36.5 6 L 11.5 6 z M 11.5 9 L 36.5 9 C 37.898226 9 39 10.101774 39 11.5 L 39 36.5 C 39 37.898226 37.898226 39 36.5 39 L 11.5 39 C 10.101774 39 9 37.898226 9 36.5 L 9 11.5 C 9 10.101774 10.101774 9 11.5 9 z M 15.5 13 A 2.5 2.5 0 0 0 15.5 18 A 2.5 2.5 0 0 0 15.5 13 z M 14 20 C 13.447 20 13 20.447 13 21 L 13 34 C 13 34.553 13.447 35 14 35 L 17 35 C 17.553 35 18 34.553 18 34 L 18 21 C 18 20.447 17.553 20 17 20 L 14 20 z M 21 20 C 20.447 20 20 20.447 20 21 L 20 34 C 20 34.553 20.447 35 21 35 L 24 35 C 24.553 35 25 34.553 25 34 L 25 26.5 C 25 25.121 26.121 24 27.5 24 C 28.879 24 30 25.121 30 26.5 L 30 34 C 30 34.553 30.447 35 31 35 L 34 35 C 34.553 35 35 34.553 35 34 L 35 26 C 35 22.691 32.309 20 29 20 C 27.462 20 26.063 20.586016 25 21.541016 L 25 21 C 25 20.447 24.553 20 24 20 L 21 20 z"></path>
            </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                // x="0px"
                // y="0px"
                width="4vw"
                style={{ maxWidth: "28px", minWidth: "20px" }}
                // height="28"
                viewBox="0 0 32 32"
              >
                <path
                  fill="#000000"
                  fill-rule="evenodd"
                  d="M2.997,26.996v-22h2v-2	h22v2h2v22h-2v2h-22v-2H2.997z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill="#ffffff"
                  fill-rule="evenodd"
                  d="M6.996,12.997v11.999	h4.001V12.997H6.996z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill="#ffffff"
                  fill-rule="evenodd"
                  d="M6.996,6.997v3.999	h4.001V6.997H6.996z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill="#ffffff"
                  fill-rule="evenodd"
                  d="M12.997,24.996v-12h4v2	h2v2h-2v8H12.997z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill="#ffffff"
                  fill-rule="evenodd"
                  d="M20.997,24.996v-10h-2	v-2h4v2h2v10H20.997z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </ol>
      </div>
    </div>
  );
};
